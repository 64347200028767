import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Reviews from "../components/Testimonials/testimonials"

const Testimonials = () => (
  <Layout>
    <Seo
      title="Testimonials"
      description="Share your own feedback and see what others are saying about Kids &amp; Teens Medical Group, trusted  Pediatrics providing care to the Glendale, Torrance, Pasadena, and West Hills, CA and surrounding Los Angeles communities."
    />
    <Reviews />
  </Layout>
)

export default Testimonials
