import React, { useEffect, useState } from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { GoStar } from "react-icons/go"
// import reviews from "./content"

import * as styles from "../../styles/components/Testimonials/testimonials.module.scss"
import { ImQuotesRight } from "react-icons/im"
import axios from "axios"
import { FaYelp } from "react-icons/fa"
import { FcGoogle } from "react-icons/fc"
import Title from "../../components/title"

import { graphql, useStaticQuery } from "gatsby"
import { useIntl, Link } from "gatsby-plugin-react-intl"

export default function Testimonials() {
  const intl = useIntl()

  const [reviews, setReviews] = useState(null)
  const [positiveReviews, setPositiveReviews] = useState(null)
  const [fiveStarReviews, setFiveStarReviews] = useState(null)

  const data = useStaticQuery(graphql`
    query {
      allContentfulLocation {
        edges {
          node {
            location
            slug
          }
        }
      }
    }
  `)

  useEffect(() => {
    axios
      .post(
        "https://api.birdeye.com/resources/v1/review/businessId/165634118873631?&count=1000&api_key=CWFOSwxffcX6I2IgH1WvF9aWvB1hmauC",
        {}
      )
      .then(response => {
        setReviews(response.data)
        return response.data.filter(review => {
          return review.rating > 3 && review.sentimentType === "POSITIVE"
        })
      })
      .then(posReviews => {
        setPositiveReviews(
          posReviews.filter(function (el) {
            // Remove duplicate items
            if (!this[el.comments]) {
              this[el.comments] = true
              return true
            }
            return false
          }, Object.create(null))
        )
      })
      .catch(e => {
        console.log(e)
      })
  }, [])

  useEffect(() => {
    setFiveStarReviews(
      reviews?.filter(review => {
        return review.rating === 5
      }).length
    )
  }, [reviews])

  const locations = data.allContentfulLocation.edges

  return (
    <>
      <Title picture title={intl.formatMessage({ id: "Testimonials" })} />
      <Container className="py-5 my-5">
        <Row>
          <Col md={6} className="mx-auto">
            <div className={``}>
              <p className={styles.text}>
                {intl.formatMessage({ id: "aboutUs0" })}{" "}
                {locations
                  .sort((a, b) => (a.node.location > b.node.location ? 1 : -1))
                  .map(({ node }, idx) => (
                    <Link
                      key={idx}
                      to={`/locations/${node.slug}`}
                      className={styles.location}
                    >{`${idx === locations.length - 1 ? "and " : " "}${
                      node.location
                    }, `}</Link>
                  ))}{" "}
                {intl.formatMessage({ id: "aboutUs1" })}{" "}
              </p>
            </div>
            <div className={`my-5`}>
              {
                // Loop Star 5 times
                Array(5)
                  .fill(0)
                  .map((_, i) => (
                    <span className="h1" key={i}>
                      <GoStar />
                    </span>
                  ))
              }
            </div>

            {fiveStarReviews && (
              <p className={`${styles.rating} my-3`}>
                {intl.formatMessage({ id: "WeHave" })}{" "}
                <span style={{ color: "rgb(2, 155, 172)" }} className="h1 mx-2">
                  <strong>{fiveStarReviews}</strong>
                </span>{" "}
                {intl.formatMessage({ id: "ReviewsRated5Stars" })}!
              </p>
            )}
            {/* 
          <div id="yelp-biz-badge-rrc-ss1-CW3KW4b8Kd2JlBU9lg">
            <a
              href="http://yelp.com/biz/pediatric-urgent-care-agoura-hills-agoura-hills?utm_medium=badge_star_rating_reviews&amp;utm_source=biz_review_badge"
              target="_blank"
            >
              Check out Pediatric Urgent Care - Agoura Hills on Yelp
            </a>
          </div> */}

            <hr className="my-4" style={{ borderColor: "black" }} />
            <p className={styles.text}>
              {intl.formatMessage({ id: "Testimonials1" })}
            </p>
            <a
              href="https://g.page/r/CUecCHG6KTsgEB0/review"
              target="_blank"
              className="me-lg-2"
            >
              <Button variant="secondary" size="bg" className={styles.btn}>
                {intl.formatMessage({ id: "TestimonialsButton1" })}
                <FcGoogle className="ms-3" />
              </Button>
            </a>

            <a
              href="https://www.yelp.com/writeareview/biz/WyYWZjBzdrmXVu0EExYkfQ?return_url=%2Fbiz%2FWyYWZjBzdrmXVu0EExYkfQ&review_origin=biz-details-war-button"
              target="_blank"
            >
              <Button variant="secondary" size="bg" className={styles.btn}>
                {intl.formatMessage({ id: "TestimonialsButton2" })}
                <FaYelp color="red" className="ms-3" />
              </Button>
            </a>
          </Col>
        </Row>
        <Row xs={1} md={2} lg={3} className={`${styles.grid} g-5 my-5`}>
          {positiveReviews?.map((review, i) => (
            <Col key={i} className={``}>
              <div className={`${styles.review}`}>
                <ImQuotesRight className="h1 mb-5" color="#0dbceb" />
                <p className={`${styles.text} h3 mb-5`}>{review.comments}</p>
                <div className="d-flex">
                  {
                    // Loop Star 5 times
                    Array(review.rating)
                      .fill(0)
                      .map((_, i) => (
                        <span className="h3" key={i}>
                          <GoStar color="gold" />
                        </span>
                      ))
                  }
                </div>
                <p className={`${styles.name} h5`}>
                  <strong>{`${review.reviewer.firstName} ${review.reviewer.lastName}`}</strong>
                </p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  )
}
